import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';

import '../generic-page.scss';
import {
  faGoogle,
  faApple,
  faReddit,
  faDiscord,
  faTwitter,
  faSteam
} from '@fortawesome/free-brands-svg-icons';
import {
  faGlobe,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LimbusAboutPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>About the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/limbuscompany/categories/category_2.jpg"
            alt="About the game"
          />
        </div>
        <div className="page-details">
          <h1>About the game</h1>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Limbus Company" />
        <p>
          Limbus Company is an Turn-Based Strategy RPG Gacha Game developed and
          published by South Korean independent video game studio Project Moon,
          serving as a sequel to Library of Ruina.
        </p>
        <p>
          The game follows the story of the aforementioned Limbus Company, a
          Fixer Office composed of twelve Fixers known as "Sinners" who are
          tasked with exploring ruined branches of the now-destroyed Lobotomy
          Corporation to recover the Golden Boughs - the essence of the
          corporation’s energy production technology - as well as any
          Enkephalin, E.G.O. and other valuables still on the site, coming into
          conflict with City denizens and escaped Abnormalities in the process.
          In order to do so, the Sinners have the ability to take different
          "Identities", where they assume the form of other versions of
          themselves from alternate realities.
        </p>
        <SectionHeader title="Project Moon" />
        <p>
          Limbus Company is a third game made by the Korean studio Project Moon.
        </p>
        <p>
          Their first game, founded by kickstarter, Lobotomy Corporation, was
          released on 9th April, 2018. It is a management simulator where
          players take care of a facility producing energy by managing entities
          known as Abnormalities.
        </p>
        <p>
          Lobotomy Corporation was followed by Library of Ruina, turn-based deck
          building rpg released on 10th August 2021 after months of early access
          period.
        </p>
        <p>
          Limbus Company is NOT a direct sequel to PM’s previous games, however
          it takes place in the same universe. There, humanity exhausted all of
          earth’s natural resources but found out an alternative called
          “Singularities” that advanced technology so far, teleportation and
          other sci-fi tech were not a mere fantasy.
        </p>
        <p>
          Action takes place in “The City”, a megacity divided into 26
          districts, which shelters remaining earth’s humanity. Outside of The
          City, is a barren wasteland occupied by cannibals, monsters and
          remnants of what was abandoned.
        </p>
        <p>
          Each district is managed by a different Corporation whose purpose is
          to produce energy and resources to run the city. Everything is ruled
          by an entity known as “The Head”.
        </p>
        <p>
          Lobotomy Corporation takes place right after the event known as Smoke
          Wars, conflict between Wings. The old L Corp would always produce
          endless amounts of acidic fog and almost always refuse to share their
          energy or charge high prices for it. This smog would end up choking
          the city, hence the namesake of the conflict. Eventually, the war
          ended with the collapse of the old L Corp and the rise of Lobotomy
          Corporation to a Wing.
        </p>
        <p>
          Library of Ruina is a sequel to Lobotomy Corporation and continues
          shortly after the events of Lobotomy Corporation ended.
        </p>
        <p>
          Limbus Company takes place at least 6 months after the events of
          Library of Ruina, if we go by webcomic / light novel “Leviathan”
          published by Project Moon, that focuses on the character of Vergilius,
          guide and advisor in the Limbus Company and how he ended up in such
          position.
        </p>
        <SectionHeader title="Official description" />
        <h5>FACE THE SIN, SAVE THE E.G.O</h5>
        <p>
          As the Executive Manager of Limbus Company, lead your group of twelve
          Sinners, venture into the buried facilities of Lobotomy Corporation,
          and lay claim on the Golden Boughs.
        </p>
        <h5>A Combination of Turn-based RPG and Realtime Brawl</h5>
        <p>
          Each turn results in a display of exhilarating action. In the combat
          phase, units on both sides will act simultaneously. During the
          scramble, characters targeting each other may Clash. The outcome of a
          Clash depends on the strength of their opposing skills and a sprinkle
          of luck; the winner can shut down the loser's skill. Ensure you use
          the right skills to win Clashes.
        </p>
        <h5>Single-handed Skirmishing</h5>
        <p>
          Connect skill icons across the grid, and your units will take action
          according to your commands. Chaining skills of the same color allows
          you to deal greater damage. Using simple and practical controls, watch
          as the Sinners dance to your tempo.
        </p>
        <h5>Create Diverse Strategies with Identities and E.G.O</h5>
        <p>
          Form teams of Identities and equip them with an arsenal of E.G.O that
          will create powerful synergies. Your foes come armed with a variety of
          Affinity-based attacks and tricky effects. In particular,
          Abnormalities are formidable foes that may prove to be considerable
          challenges. Learn their behavior and weaknesses, and compose the right
          team to eventually conquer them.
        </p>
        <h5>Immerse Yourself in the World of Project Moon</h5>
        <p>
          From the creators of Lobotomy Corporation and Library of Ruina,
          another tale set in the same, unique world unfolds. In this massive
          dystopian metropolis, you will embark on a journey to find Golden
          Boughs with a cast of 12 Sinners. Enjoy the gripping story and the
          series of events that await. All dialogue in the main story comes
          fully voiced in Korean.
        </p>
        <p>Best of luck, Dear Manager.</p>
        <SectionHeader title="Official links" />
        <Tabs
          defaultActiveKey="Global"
          transition={false}
          id="current-events-tabs"
          className="current-event-tabs"
        >
          <Tab eventKey="Global" title="Global">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://limbuscompany.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faTwitter} /> Twitter
                    </div>
                    <div className="list-value">
                      <a
                        href="https://twitter.com/LimbusCompany_B"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ProjectMoon.LimbusCompany"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/app/limbus-company/id6444112366"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faSteam} /> Steam
                    </div>
                    <div className="list-value">
                      <a
                        href="https://store.steampowered.com/app/1973530/Limbus_Company/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">
                      <a
                        href="https://www.reddit.com/r/limbuscompany/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">-</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default LimbusAboutPage;

export const Head: React.FC = () => (
  <Seo
    title="About the game | Limbus Company | Prydwen Institute"
    description="Learn more about Limbus Company gacha game."
    game="limbus"
  />
);
